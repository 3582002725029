import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`Buy In: 1000M Row`}</p>
    <p>{`25-20-15-10-5 each of:`}</p>
    <p>{`Bar Dips`}</p>
    <p>{`Ring Rows`}</p>
    <p>{`Back Extension`}</p>
    <p>{`Hanging L-Hold (seconds)`}</p>
    <p>{`Cash Out: 1000M Row`}</p>
    <p>{`For time.`}</p>
    <h4><strong parentName="h4"><em parentName="strong">{`*`}{`CrossFit the Ville’s Barbell Club will meet today after class from 1:45-3:15.  We’ll be covering mobility and technique drills for the Snatch today.`}</em></strong></h4>
    <h4><strong parentName="h4"><em parentName="strong">{`*`}{`The CrossFit Open starts February 21st! To prepare we’ll be throwing in some past Open WOD’s in our class programming. Register for the Open at: Games.CrossFit.com.`}</em></strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      